import React from 'react';

const LyricsDisplay = ({ lyrics, currentTime, duration }) => {
  if (!lyrics) return null;

  const lines = lyrics.split('\n').filter(line => line.trim() !== '');
  const totalLines = lines.length;
  const timePerLine = duration / totalLines;

  return (
    <div className="mt-4 mb-4 bg-white bg-opacity-10 p-3 rounded-lg max-h-60 overflow-y-auto">
      <h3 className="text-sm font-semibold mb-2">Lyrics:</h3>
      <div className="space-y-1">
        {lines.map((line, index) => (
          <p
            key={index}
            className={`text-xs transition-colors duration-300 ${
              currentTime >= index * timePerLine && currentTime < (index + 1) * timePerLine
                ? 'text-birthday-yellow font-bold'
                : 'text-white'
            }`}
          >
            {line}
          </p>
        ))}
      </div>
    </div>
  );
};

export default LyricsDisplay;