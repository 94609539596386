import React, { useState } from 'react';
import Select from 'react-select';

function GenreSelector({ genreOptions, onChange }) {
  const [selectedGenres, setSelectedGenres] = useState([]);

  const handleGenreChange = (selected, actionMeta) => {
    setSelectedGenres(selected);
    onChange(selected.map(item => item.value).join(', '));
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      borderColor: '#e2e8f0',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#4299e1' : state.isFocused ? '#ebf8ff' : 'white',
      color: state.isSelected ? 'white' : 'black',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4299e1',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: '#2b6cb0',
        color: 'white',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#718096',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
    }),
  };

  return (
    <div>
      <Select
        isMulti
        name="genres"
        options={genreOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleGenreChange}
        value={selectedGenres}
        placeholder="Search and select genres..."
        styles={customStyles}
      />
      
    </div>
  );
}

export default GenreSelector;