import React, { useState } from 'react';
import { FaShare, FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa';

const ShareButton = ({ songUrl }) => {
  const [showOptions, setShowOptions] = useState(false);

  const shareUrl = encodeURIComponent(songUrl);
  const shareText = encodeURIComponent("Check out this awesome birthday song I created!");

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    twitter: `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`,
    whatsapp: `https://api.whatsapp.com/send?text=${shareText}%20${shareUrl}`
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowOptions(!showOptions)}
        className="flex-1 px-4 py-2 bg-birthday-blue text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300 flex items-center justify-center"
      >
        <FaShare className="mr-2" /> Share
      </button>
      {showOptions && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          <a href={shareLinks.facebook} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center">
            <FaFacebook className="mr-2" /> Facebook
          </a>
          <a href={shareLinks.twitter} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center">
            <FaTwitter className="mr-2" /> Twitter
          </a>
          <a href={shareLinks.whatsapp} target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center">
            <FaWhatsapp className="mr-2" /> WhatsApp
          </a>
        </div>
      )}
    </div>
  );
};

export default ShareButton;