import React, { useMemo } from 'react';

const Balloon = React.memo(({ color, style }) => (
  <svg width="30" height="40" viewBox="0 0 30 40" style={style}>
    <defs>
      <radialGradient id={`grad_${color}`} cx="50%" cy="40%" r="50%" fx="50%" fy="40%">
        <stop offset="0%" stopColor={color} stopOpacity="1" />
        <stop offset="100%" stopColor={color} stopOpacity="0.6" />
      </radialGradient>
    </defs>
    <path d="M15 2C7.8 2 2 7.8 2 15C2 22.2 7.8 28 15 28C22.2 28 28 22.2 28 15C28 7.8 22.2 2 15 2Z" fill={`url(#grad_${color})`} />
    <path d="M15 28V38" stroke={color} strokeWidth="1.5" />
    <path d="M13 36H17" stroke={color} strokeWidth="1.5" />
  </svg>
));

const generateBalloons = (count) => {
  const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#F9D56E', '#FF8C94', '#9B59B6'];
  return Array.from({ length: count }, (_, i) => ({
    id: i,
    color: colors[Math.floor(Math.random() * colors.length)],
    left: `${Math.random() * 100}%`,
    bottom: `-${Math.random() * 20 + 5}%`,
    animationDelay: `${Math.random() * 10}s`,
    animationDuration: `${20 + Math.random() * 20}s`,
    scale: 0.5 + Math.random() * 1,
    swayDuration: `${5 + Math.random() * 5}s`,
  }));
};

const AnimatedBackground = React.memo(() => {
  const balloons = useMemo(() => generateBalloons(30), []);

  return (
    <div className="fixed inset-0 z-0 overflow-hidden pointer-events-none">
      {balloons.map((balloon) => (
        <div
          key={balloon.id}
          className="absolute animate-float-up"
          style={{
            left: balloon.left,
            bottom: balloon.bottom,
            animationDelay: balloon.animationDelay,
            animationDuration: balloon.animationDuration,
          }}
        >
          <Balloon 
            color={balloon.color}
            style={{
              transform: `scale(${balloon.scale})`,
              animation: `sway ${balloon.swayDuration} ease-in-out infinite alternate`,
            }} 
          />
        </div>
      ))}
    </div>
  );
});

export default AnimatedBackground;