import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FaUserAlt, FaLock, FaDownload, FaCopy } from 'react-icons/fa';
import { GiPartyPopper } from 'react-icons/gi';
import ShareButton from './components/ShareButton';
import MarketingPage from './components/MarketingPage';
import AnimatedBackground from './components/AnimatedBackground';
import LyricsDisplay from './components/LyricsDisplay';
import GenreSelector from './components/GenreSelector';
const stripePromise = loadStripe('pk_test_51Q7JHzHJAHJtsAn5zEtRjbuve8Uu0r4rNjtR2i9jgG0I9xY372wH7lpWB2KnfbUqt5vX7JlxQt4VxFvbKKqNuh5X003yujBEso');

function PaymentForm({ onPaymentSuccess }) {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [couponCode, setCouponCode] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);

    try {
      const response = await axios.post('/api/create-payment-intent', { couponCode });
      const { clientSecret, sessionId, couponApplied } = response.data;

      if (couponApplied) {
        onPaymentSuccess(sessionId);
      } else {
        if (!stripe || !elements) {
          throw new Error('Stripe.js has not loaded yet.');
        }

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });

        if (result.error) {
          setError(result.error.message);
        } else {
          await axios.post('/api/confirm-payment', { sessionId });
          onPaymentSuccess(sessionId);
        }
      }
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred. Please try again.');
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <input
        type="text"
        value={couponCode}
        onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
        placeholder="Enter coupon code (optional)"
        className="w-full px-2 py-1 text-xs sm:text-sm placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 text-gray-800 mb-2"
      />
      <CardElement className="p-2 border border-gray-300 rounded-md text-xs sm:text-sm" />
      <button 
        type="submit" 
        disabled={processing}
        className="w-full mt-3 px-3 py-2 text-white bg-birthday-blue rounded-md focus:outline-none hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300 flex items-center justify-center text-xs sm:text-sm"
      >
        <FaLock className="mr-1" />
        {processing ? 'Processing...' : 'Pay $0.99'}
      </button>
      {error && <div className="text-red-500 mt-2 text-xs sm:text-sm">{error}</div>}
    </form>
  );
}

function App() {
  const [name, setName] = useState('');
  const [uniqueTrait, setUniqueTrait] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [genre, setGenre] = useState('');
  const [sessionId, setSessionId] = useState(null);
  const [lyrics, setLyrics] = useState([]);
  const [selectedLyricsIndex, setSelectedLyricsIndex] = useState(null);
  const [songUrl, setSongUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [attemptsLeft, setAttemptsLeft] = useState(5);
  const [showApp, setShowApp] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [uniqueId, setUniqueId] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [genres, setGenres] = useState('');

  const genreOptions = [
    {
      value: 'Style',
      label: 'Style',
      options: [
        { value: 'Danceable', label: 'Danceable' },
        { value: 'Dance', label: 'Dance' },
        { value: 'Festive', label: 'Festive' },
        { value: 'Groovy', label: 'Groovy' },
        { value: 'Mid-Tempo', label: 'Mid-Tempo' },
        { value: 'Syncopated', label: 'Syncopated' },
        { value: 'Tipsy', label: 'Tipsy' },
        { value: 'Dark', label: 'Dark' },
        { value: 'Atmospheric', label: 'Atmospheric' },
        { value: 'Cold', label: 'Cold' },
        { value: 'Doom', label: 'Doom' },
        { value: 'Dramatic', label: 'Dramatic' },
        { value: 'Sinister', label: 'Sinister' },
        { value: 'Eclectic', label: 'Eclectic' },
        { value: 'Adjunct', label: 'Adjunct' },
        { value: 'Art', label: 'Art' },
        { value: 'Capriccio', label: 'Capriccio' },
        { value: 'Mellifluous', label: 'Mellifluous' },
        { value: 'Nü', label: 'Nü' },
        { value: 'Progressive', label: 'Progressive' },
        { value: 'Unusual', label: 'Unusual' },
        { value: 'Emotion', label: 'Emotion' },
        { value: 'Anthemic', label: 'Anthemic' },
        { value: 'Emotional', label: 'Emotional' },
        { value: 'Happy', label: 'Happy' },
        { value: 'Jubilant', label: 'Jubilant' },
        { value: 'Melancholy', label: 'Melancholy' },
        { value: 'Sad', label: 'Sad' },
        { value: 'Hard', label: 'Hard' },
        { value: 'Aggressive', label: 'Aggressive' },
        { value: 'Banger', label: 'Banger' },
        { value: 'Power', label: 'Power' },
        { value: 'Stadium', label: 'Stadium' },
        { value: 'Stomp', label: 'Stomp' },
        { value: 'Lyrical', label: 'Lyrical' },
        { value: 'Broadway', label: 'Broadway' },
        { value: 'Cabaret', label: 'Cabaret' },
        { value: 'Lounge', label: 'Lounge' },
        { value: 'Operatic', label: 'Operatic' },
        { value: 'Storytelling', label: 'Storytelling' },
        { value: 'Torch-Lounge', label: 'Torch-Lounge' },
        { value: 'Theatrical', label: 'Theatrical' },
        { value: 'Troubadour', label: 'Troubadour' },
        { value: 'Vegas', label: 'Vegas' },
        { value: 'Magical', label: 'Magical' },
        { value: 'Ethereal', label: 'Ethereal' },
        { value: 'Majestic', label: 'Majestic' },
        { value: 'Mysterious', label: 'Mysterious' },
        { value: 'Minimal', label: 'Minimal' },
        { value: 'Ambient', label: 'Ambient' },
        { value: 'Cinematic', label: 'Cinematic' },
        { value: 'Heat', label: 'Heat' },
        { value: 'Slow', label: 'Slow' },
        { value: 'Sparse', label: 'Sparse' },
        { value: 'Party', label: 'Party' },
        { value: 'German Schlager', label: 'German Schlager' },
        { value: 'Glam', label: 'Glam' },
        { value: 'Glitter', label: 'Glitter' },
        { value: 'Soft', label: 'Soft' },
        { value: 'Bedroom', label: 'Bedroom' },
        { value: 'Chillwave', label: 'Chillwave' },
        { value: 'Intimate', label: 'Intimate' },
        { value: 'Sadcore', label: 'Sadcore' },
        { value: 'Weird', label: 'Weird' },
        { value: 'Carnival', label: 'Carnival' },
        { value: 'Distorted', label: 'Distorted' },
        { value: 'Glitchy', label: 'Glitchy' },
        { value: 'Haunted', label: 'Haunted' },
        { value: 'Hollow', label: 'Hollow' },
        { value: 'Musicbox', label: 'Musicbox' },
        { value: 'Random', label: 'Random' },
        { value: 'World/Ethnic', label: 'World/Ethnic' },
        { value: 'Arabian', label: 'Arabian' },
        { value: 'Bangra', label: 'Bangra' },
        { value: 'Calypso', label: 'Calypso' },
        { value: 'Chalga', label: 'Chalga' },
        { value: 'Egyptian', label: 'Egyptian' },
        { value: 'Hindustani', label: 'Hindustani' },
        { value: 'Hōgaku', label: 'Hōgaku' },
        { value: 'Jewish Music', label: 'Jewish Music' },
        { value: 'Klezmer', label: 'Klezmer' },
        { value: 'Matsuri', label: 'Matsuri' },
        { value: 'Middle East', label: 'Middle East' },
        { value: 'Polka', label: 'Polka' },
        { value: 'Russian Navy Song', label: 'Russian Navy Song' },
        { value: 'Suomipop', label: 'Suomipop' },
        { value: 'Tribal', label: 'Tribal' }
      ]
    },
    {
      value: 'Genre',
      label: 'Genre',
      options: [
        { value: 'Country', label: 'Country' },
        { value: 'Appalachian', label: 'Appalachian' },
        { value: 'Bluegrass', label: 'Bluegrass' },
        { value: 'Folk', label: 'Folk' },
        { value: 'Freak Folk', label: 'Freak Folk' },
        { value: 'Western', label: 'Western' },
        { value: 'Dance', label: 'Dance' },
        { value: 'Afro-Cuban', label: 'Afro-Cuban' },
        { value: 'Dance Pop', label: 'Dance Pop' },
        { value: 'Disco', label: 'Disco' },
        { value: 'Dubstep', label: 'Dubstep' },
        { value: 'Disco Funk', label: 'Disco Funk' },
        { value: 'EDM', label: 'EDM' },
        { value: 'Electro', label: 'Electro' },
        { value: 'High-NRG', label: 'High-NRG' },
        { value: 'House', label: 'House' },
        { value: 'Trance', label: 'Trance' },
        { value: 'Downtempo', label: 'Downtempo' },
        { value: 'Ambient', label: 'Ambient' },
        { value: 'Synthwave', label: 'Synthwave' },
        { value: 'Trap', label: 'Trap' },
        { value: 'Electronic', label: 'Electronic' },
        { value: 'Cyberpunk', label: 'Cyberpunk' },
        { value: 'Drum\'n\'bass', label: 'Drum\'n\'bass' },
        { value: 'Hypnogogical', label: 'Hypnogogical' },
        { value: 'IDM', label: 'IDM' },
        { value: 'Phonk', label: 'Phonk' },
        { value: 'Synthpop', label: 'Synthpop' },
        { value: 'Techno', label: 'Techno' },
        { value: 'Jazz/Soul', label: 'Jazz/Soul' },
        { value: 'Bebop', label: 'Bebop' },
        { value: 'Gospel', label: 'Gospel' },
        { value: 'Frutiger Aero', label: 'Frutiger Aero' },
        { value: 'Jazz', label: 'Jazz' },
        { value: 'Latin Jazz', label: 'Latin Jazz' },
        { value: 'RnB', label: 'RnB' },
        { value: 'Soul', label: 'Soul' },
        { value: 'Latin', label: 'Latin' },
        { value: 'Bossa Nova', label: 'Bossa Nova' },
        { value: 'Forró', label: 'Forró' },
        { value: 'Mambo', label: 'Mambo' },
        { value: 'Salsa', label: 'Salsa' },
        { value: 'Tango', label: 'Tango' },
        { value: 'Reggae', label: 'Reggae' },
        { value: 'Afrobeat', label: 'Afrobeat' },
        { value: 'Dancehall', label: 'Dancehall' },
        { value: 'Dub', label: 'Dub' },
        { value: 'Reggaeton', label: 'Reggaeton' },
        { value: 'Metal', label: 'Metal' },
        { value: 'Black Metal', label: 'Black Metal' },
        { value: 'Deathcore', label: 'Deathcore' },
        { value: 'Death Metal', label: 'Death Metal' },
        { value: 'Heavy Metal', label: 'Heavy Metal' },
        { value: 'Heavy Metal Trap', label: 'Heavy Metal Trap' },
        { value: 'Metalcore', label: 'Metalcore' },
        { value: 'Nu Metal', label: 'Nu Metal' },
        { value: 'Power Metal', label: 'Power Metal' },
        { value: 'Popular', label: 'Popular' },
        { value: 'Pop', label: 'Pop' },
        { value: 'Pop Rock', label: 'Pop Rock' },
        { value: 'Kpop', label: 'Kpop' },
        { value: 'Jpop', label: 'Jpop' },
        { value: 'Rock', label: 'Rock' },
        { value: 'Classic Rock', label: 'Classic Rock' },
        { value: 'Blues Rock', label: 'Blues Rock' },
        { value: 'Emo', label: 'Emo' },
        { value: 'Glam Rock', label: 'Glam Rock' },
        { value: 'Hardcore Punk', label: 'Hardcore Punk' },
        { value: 'Indie', label: 'Indie' },
        { value: 'Industrial Rock', label: 'Industrial Rock' },
        { value: 'Punk', label: 'Punk' },
        { value: 'Skate Rock', label: 'Skate Rock' },
        { value: 'Skatecore', label: 'Skatecore' },
        { value: 'Urban', label: 'Urban' },
        { value: 'Funk', label: 'Funk' },
        { value: 'HipHop', label: 'HipHop' },
        { value: 'Rap', label: 'Rap' }
      ]
    },
    {
      value: 'Type',
      label: 'Type',
      options: [
        { value: 'Background', label: 'Background' },
        { value: 'Elevator', label: 'Elevator' },
        { value: 'Jingle', label: 'Jingle' },
        { value: 'Muzak', label: 'Muzak' },
        { value: 'Call to Prayer', label: 'Call to Prayer' },
        { value: 'Adan', label: 'Adan' },
        { value: 'Adjan', label: 'Adjan' },
        { value: 'Gregorian Chant', label: 'Gregorian Chant' },
        { value: 'Character', label: 'Character' },
        { value: 'I Want Song', label: 'I Want Song' },
        { value: 'Hero Theme', label: 'Hero Theme' },
        { value: 'Strut', label: 'Strut' },
        { value: 'March', label: 'March' },
        { value: 'Military', label: 'Military' },
        { value: 'Villain Theme', label: 'Villain Theme' },
        { value: 'Children', label: 'Children' },
        { value: 'Lullaby', label: 'Lullaby' },
        { value: 'Nursery Rhyme', label: 'Nursery Rhyme' },
        { value: 'Sing-along', label: 'Sing-along' },
        { value: 'Toddler', label: 'Toddler' }
      ]
    },
    {
      value: 'Composer',
      label: 'Composer',
      options: [
        { value: 'Adagio', label: 'Adagio' },
        { value: 'Adjunct', label: 'Adjunct' },
        { value: 'Andante', label: 'Andante' },
        { value: 'Allegro', label: 'Allegro' },
        { value: 'Capriccio', label: 'Capriccio' }
      ]
    },
    {
      value: 'Instruments',
      label: 'Instruments',
      options: [
        { value: 'Acoustic Guitar', label: 'Acoustic Guitar' },
        { value: 'Bass', label: 'Bass' },
        { value: 'Doublebass', label: 'Doublebass' },
        { value: 'Electricbass', label: 'Electricbass' },
        { value: 'Electric Guitar', label: 'Electric Guitar' },
        { value: 'Fingerstyle Guitar', label: 'Fingerstyle Guitar' },
        { value: 'Percussion', label: 'Percussion' },
        { value: 'Noise', label: 'Noise' },
        { value: 'Chaotic', label: 'Chaotic' },
        { value: 'Distorted', label: 'Distorted' },
        { value: 'Glitch', label: 'Glitch' },
        { value: 'Random', label: 'Random' },
        { value: 'Stuttering', label: 'Stuttering' },
        { value: 'Orchestral', label: 'Orchestral' },
        { value: 'glissando trombone', label: 'glissando trombone' },
        { value: 'legato cello', label: 'legato cello' },
        { value: 'spiccato violins', label: 'spiccato violins' },
        { value: 'staccato viola', label: 'staccato viola' },
        { value: 'Symphonic', label: 'Symphonic' }
      ]
    },
    {
      value: 'Retro',
      label: 'Retro',
      options: [
        { value: '1960s', label: '1960s' },
        { value: 'Barbershop', label: 'Barbershop' },
        { value: 'Big Band', label: 'Big Band' },
        { value: 'Classic', label: 'Classic' },
        { value: 'Doo Wop', label: 'Doo Wop' },
        { value: 'Girl Group', label: 'Girl Group' },
        { value: 'Mambo', label: 'Mambo' },
        { value: 'Salooncore', label: 'Salooncore' },
        { value: 'Swing', label: 'Swing' }
      ]
    },
    {
      value: 'Traditional',
      label: 'Traditional',
      options: [
        { value: 'Americana', label: 'Americana' },
        { value: 'Barbershop', label: 'Barbershop' },
        { value: 'Christmas Carol', label: 'Christmas Carol' },
        { value: 'Traditional', label: 'Traditional' }
      ]
    },
    {
      value: 'Suffix',
      label: 'Suffix',
      options: [
        { value: '…core', label: '…core' },
        { value: '…jam', label: '…jam' },
        { value: '…out', label: '…out' },
        { value: '…wave', label: '…wave' }
      ]
    },
    {
      value: 'Voice',
      label: 'Voice',
      options: [
        { value: 'A Cappella', label: 'A Cappella' },
        { value: 'Arabian Ornamental', label: 'Arabian Ornamental' },
        { value: 'Dispassionate', label: 'Dispassionate' },
        { value: 'Emotional', label: 'Emotional' },
        { value: 'Ethereal', label: 'Ethereal' },
        { value: 'Gregorian chant', label: 'Gregorian chant' },
        { value: 'Hindustani', label: 'Hindustani' },
        { value: 'Lounge Singer', label: 'Lounge Singer' },
        { value: 'Melismatic', label: 'Melismatic' },
        { value: 'Monotone', label: 'Monotone' },
        { value: 'Narration', label: 'Narration' },
        { value: 'Resonant', label: 'Resonant' },
        { value: 'Spoken Word', label: 'Spoken Word' },
        { value: 'Sprechgesang', label: 'Sprechgesang' },
        { value: 'Sultry', label: 'Sultry' },
        { value: 'Scream', label: 'Scream' },
        { value: 'Torchy', label: 'Torchy' },
        { value: 'Vocaloid', label: 'Vocaloid' }
      ]
    }
  ];

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const loadSongByUniqueId = useCallback(async (id) => {
    try {
      const { data } = await axios.get(`/api/song/${id}`);
      setSongUrl(data.songUrl);
      setUniqueId(id);
      setShowApp(true);
      setLyrics([data.lyrics]); // Set lyrics as a single-item array
      setSelectedLyricsIndex(0); // Set the index to 0 since there's only one set of lyrics
      setSessionId('loaded'); // This will bypass the payment step
      setGenre(data.genre);
    } catch (err) {
      setError('Failed to load song. Please try again.');
    }
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    const match = path.match(/^\/song\/(.+)$/);
    if (match) {
      const id = match[1];
      loadSongByUniqueId(id);
    }
  }, [loadSongByUniqueId]); 

  const handleGetStarted = () => {
    setShowApp(true);
  };

  if (!showApp) {
    return <MarketingPage onGetStarted={handleGetStarted} />;
  }

  const handlePaymentSuccess = (id) => {
    setSessionId(id);
  };

  const handleGenerateLyrics = async () => {
    setLoading(true);
    setError(null);

    if (lyrics.length >= 5) {
      setError('You have reached the maximum number of lyrics generation attempts.');
      setLoading(false);
      return;
    }

    try {
      const { data } = await axios.post('/api/generate-lyrics', { 
        sessionId, 
        name, 
        uniqueTrait, 
        genres: genres
      });
      setLyrics([...lyrics, data.lyrics]);
      setAttemptsLeft(attemptsLeft - 1);
    } catch (err) {
      setError('Failed to generate lyrics. Please try again.');
    }

    setLoading(false);
  };

  const handleSelectLyrics = async (index) => {
    setSelectedLyricsIndex(index);
    try {
      await axios.post('/api/select-lyrics', { sessionId, lyricsIndex: index });
      setSelectedLyricsIndex(index);
    } catch (err) {
      setError('Failed to select lyrics. Please try again.');
    }
  };

  const handleGenerateSong = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await axios.post('/api/generate-song', { 
        sessionId, 
        genres: selectedGenres.map(g => g.value).join(', ')
      });
      setSongUrl(data.songUrl);
      setUniqueId(data.uniqueId);
      window.history.pushState(null, '', `/song/${data.uniqueId}`);
      pollForTransferredUrl(sessionId);
    } catch (err) {
      setError('Failed to generate song. Please try again.');
    }
    setLoading(false);
  };
  
  const pollForTransferredUrl = async (sessionId) => {
    const pollInterval = setInterval(async () => {
      try {
        const { data } = await axios.get(`/api/song-status/${sessionId}`);
        if (data.status === 'transferred') {
          setSongUrl(data.songUrl);
          clearInterval(pollInterval);
        } else if (data.status === 'transfer_failed') {
          setError('Failed to transfer song. Please try again later.');
          clearInterval(pollInterval);
        }
      } catch (error) {
        console.error('Error polling for song status:', error);
      }
    }, 10000); // Poll every 10 seconds
  };

  

  const handleCopyLink = () => {
    const songSelfieUrl = `${window.location.origin}/song/${uniqueId}`;
    navigator.clipboard.writeText(songSelfieUrl).then(() => {
      alert('Song link copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-birthday-pink via-birthday-blue to-birthday-yellow text-white relative overflow-hidden">
      <AnimatedBackground />
      <div className="container mx-auto px-4 py-8 sm:py-12 md:py-16 relative z-10">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-6 sm:mb-8 animate-pulse">
          <span className="whitespace-nowrap">SongSelfie:</span>
          <span className="whitespace-nowrap">Your Life, Your Lyrics</span>
        </h1>
        
        {!sessionId ? (
          <div className="max-w-sm sm:max-w-md mx-auto bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-4 sm:p-6 shadow-lg">
            <h2 className="text-lg sm:text-xl font-bold mb-3">Step 1: Capture Your Musical Moment</h2>
            <p className="mb-3 text-xs sm:text-sm">For just $0.99, create a SongSelfie that's as unique as you are!</p>
            <div className="bg-white bg-opacity-10 p-3 rounded-lg mb-3">
              <h3 className="font-bold mb-2 text-sm">What you'll get in your SongSelfie:</h3>
              <ul className="list-disc list-inside text-xs sm:text-sm">
                <li>A custom track that's all about you</li>
                <li>Lyric options that capture your essence</li>
                <li>High-quality audio to share your story</li>
                <li>Easy sharing to show off your musical you</li>
              </ul>
            </div>
            <Elements stripe={stripePromise}>
              <PaymentForm onPaymentSuccess={handlePaymentSuccess} />
            </Elements>
          </div>
        ) : !songUrl ? (
          <div className="max-w-sm sm:max-w-md mx-auto bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-4 sm:p-6 shadow-lg">
            <h2 className="text-lg sm:text-xl font-bold mb-3">Step 2: Compose Your SongSelfie</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleGenerateLyrics(); }} className="space-y-3">
              <div>
                <label className="block text-xs sm:text-sm font-bold mb-1" htmlFor="name">
                  <FaUserAlt className="inline-block mr-1" />
                  Recipient's Name
                </label>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
                  required
                  className="w-full px-2 py-1 text-xs sm:text-sm placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 text-gray-800"
                />
              </div>
              <div>
            <label className="block text-xs sm:text-sm font-bold mb-1" htmlFor="uniqueTrait">
              <FaUserAlt className="inline-block mr-1" />
              Unique Trait
            </label>
            <input
              id="uniqueTrait"
              type="text"
              value={uniqueTrait}
              onChange={(e) => setUniqueTrait(e.target.value)}
              placeholder="Enter a unique trait"
              required
              className="w-full px-2 py-1 text-xs sm:text-sm placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 text-gray-800"
            />
          </div>
          <div>
            <label className="block text-xs sm:text-sm font-bold mb-1" htmlFor="genre">
              <FaUserAlt className="inline-block mr-1" />
              Genres
            </label>
            <GenreSelector genreOptions={genreOptions} onChange={(selectedGenres) => setGenres(selectedGenres)} />
          </div>
          <button 
            type="submit" 
            disabled={loading || attemptsLeft === 0}
            className="w-full px-3 py-2 text-white bg-birthday-blue rounded-md focus:bg-birthday-pink focus:outline-none hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300 text-xs sm:text-sm"
          >
            {loading ? 'Generating...' : `Generate Lyrics (${attemptsLeft} attempts left)`}
          </button>
        </form>
        {lyrics.length > 0 && (
              <div className="mt-4">
                <h3 className="text-sm font-semibold mb-2">Generated Lyrics Options:</h3>
                {lyrics.map((lyric, index) => (
                  <div key={index} className={`mb-2 p-2 bg-white bg-opacity-10 rounded ${selectedLyricsIndex === index ? 'border-2 border-birthday-yellow' : ''}`}>
                    <p className="text-xs">{lyric}</p>
                    <button
                      onClick={() => handleSelectLyrics(index)}
                      className={`mt-1 px-2 py-1 text-xs ${selectedLyricsIndex === index ? 'bg-birthday-yellow text-gray-800' : 'bg-birthday-blue text-white'} rounded hover:bg-birthday-yellow hover:text-gray-800`}
                    >
                      {selectedLyricsIndex === index ? 'Selected' : 'Select'}
                    </button>
                  </div>
                ))}
              </div>
            )}
            {selectedLyricsIndex !== null && (
              <button
                onClick={handleGenerateSong}
                className="w-full mt-4 px-3 py-2 bg-birthday-pink text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300 text-xs sm:text-sm"
              >
                Generate Song
              </button>
            )}
          </div>
        ) : (
          <div className="max-w-sm sm:max-w-md mx-auto bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-4 sm:p-6 shadow-lg">
            <h2 className="text-lg sm:text-xl font-bold mb-3 text-center">Your Birthday Song is Ready!</h2>
            <audio 
              controls 
              src={songUrl} 
              className="w-full mb-4 bg-white rounded-full" 
              onTimeUpdate={handleTimeUpdate}
              onLoadedMetadata={handleLoadedMetadata}
              ref={audioRef}
            />
            <LyricsDisplay 
              lyrics={lyrics[selectedLyricsIndex]} 
              currentTime={currentTime} 
              duration={duration}
            />
            <div className="flex flex-col space-y-2 mb-4">
              <a
                href={songUrl}
                download
                target="_blank"
                rel="noopener noreferrer"
                className="w-full px-3 py-2 bg-birthday-blue text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300 flex items-center justify-center text-xs sm:text-sm"
              >
                <FaDownload className="mr-1" /> Download
              </a>
              <button
                onClick={handleCopyLink}
                className="w-full px-3 py-2 bg-birthday-pink text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300 flex items-center justify-center text-xs sm:text-sm"
              >
                <FaCopy className="mr-1" /> Copy Link
              </button>
              <ShareButton songUrl={songUrl} />
            </div>
            <div>
              <h3 className="text-sm font-semibold mb-1">Embed this song:</h3>
              <textarea
                readOnly
                value={`<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="${songUrl}"></iframe>`}
                className="w-full h-20 p-2 text-xs text-gray-700 bg-white bg-opacity-80 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                onClick={(e) => e.target.select()}
              />
            </div>
            <button
              onClick={() => {
                setSessionId(null);
                setSongUrl(null);
                setLyrics([]);
                setSelectedLyricsIndex(null);
                setName('');
                setUniqueTrait('');
                setGenre('');
                setAttemptsLeft(5);
                setUniqueId(null);
                window.history.pushState(null, '', '/');
              }}
              className="w-full mt-3 px-3 py-2 bg-birthday-pink text-white rounded-md hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300 flex items-center justify-center text-xs sm:text-sm"
            >
              <GiPartyPopper className="mr-1" /> Generate Another Song
            </button>
          </div>
        )}
        
        {error && <p className="text-red-500 text-center mt-3 text-xs sm:text-sm">{error}</p>}
      </div>
    </div>
  );
}

export default App;