import React from 'react';
import { FaMusic, FaShare, FaDownload } from 'react-icons/fa';

const MarketingPage = ({ onGetStarted }) => {
  const exampleSongUrl = process.env.PUBLIC_URL + "/client/birthday-song.mp3";

  return (
    <div className="min-h-screen bg-gradient-to-br from-birthday-pink via-birthday-blue to-birthday-yellow text-white relative overflow-hidden">
      <div className="container mx-auto px-4 py-8 relative z-10">
        <h1 className="text-2xl sm:text-4xl font-bold text-center mb-4">
          <span className="whitespace-nowrap">SongSelfie:</span>
          <span className="whitespace-nowrap">Your Life, Your Lyrics</span>
        </h1>
        <p className="text-lg sm:text-xl text-center mb-8">
          Create a unique song that captures your essence in just minutes!
        </p>
        <div className="mb-8 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-4 shadow-lg">
          <h2 className="text-xl font-bold text-center mb-3">Listen to an example SongSelfie:</h2>
          <audio controls src={exampleSongUrl} className="w-full max-w-md mx-auto" />
        </div>
        <div className="grid gap-6 mb-8">
          <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-4 rounded-lg">
            <FaMusic className="text-3xl mb-2" />
            <h2 className="text-xl font-bold mb-2">Personalized Tracks</h2>
            <p className="text-sm">AI-generated songs tailored to your unique story and style.</p>
          </div>
          <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-4 rounded-lg">
            <FaShare className="text-3xl mb-2" />
            <h2 className="text-xl font-bold mb-2">Easy Sharing</h2>
            <p className="text-sm">Share your musical creation on all major social platforms.</p>
          </div>
          <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg p-4 rounded-lg">
            <FaDownload className="text-3xl mb-2" />
            <h2 className="text-xl font-bold mb-2">High-Quality Audio</h2>
            <p className="text-sm">Download and keep your SongSelfie in studio-quality audio.</p>
          </div>
        </div>
        <button
          onClick={onGetStarted}
          className="w-full max-w-md mx-auto block px-6 py-3 bg-birthday-blue text-white rounded-full text-lg font-bold hover:bg-birthday-yellow hover:text-gray-800 transition-colors duration-300"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default MarketingPage;